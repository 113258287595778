// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`BannerBox.vue Given a simple banner box divided in half.,  It does render simple banner box divided in half. 1`] = `
<div
  class="relative overflow-hidden w-full flex flex-row-reverse"
>
  <div
    class="w-1/2 h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-1/2 lg:absolute bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box in medium dimension.,  It does render simple banner box in medium dimension. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[260px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box in mini dimension.,  It does render simple banner box in mini dimension. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[150px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] h-[88px] lg:h-[150px]"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-2 lg:m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box where full text box is provided.,  It renders simple banner box with full text box. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex bottom-0 left-0 h-full w-full lg:w-[300px]"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box with badge.,  It does render simple banner box with badge. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey="lorem ipsum"
        class="block w-max"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box with round button on mobile version.,  It does render simple banner box with round button on mobile version. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        isroundchevronmobile="true"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box with strap and icon with full image.,  It renders simple banner box with strap, icon and full image. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 flex"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45 icon-youtube"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-10 justify-around"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-3xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class=""
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box with strap and icon.,  It renders simple banner box with strap and icon. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 flex"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45 icon-youtube"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box without button.,  It does renders simple banner box without button. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <!---->
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue Given a simple banner box.,  It renders simple banner box. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src=""
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue When mobile image is provided and mobileImageAutoHeight is true,  Renders dedicated image for mobile devices and sets the height of it to auto. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-auto lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover hidden lg:block"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full block lg:hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="/cms-images/LP-Black-Friday-2024-bg-under-clock-mobile.webp"
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;

exports[`BannerBox.vue When mobile image is provided,  Renders dedicated image for mobile devices. 1`] = `
<div
  class="relative overflow-hidden w-full"
>
  <div
    class="h-[200px] lg:h-[360px]"
  >
    <imgview-stub
      alt=""
      class="object-cover hidden lg:block"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="https://www.expondo.co.uk/inspirations/wp-content/uploads/2023/01/microwaveable-dishes.jpg"
      width="0"
    />
     
    <imgview-stub
      alt=""
      class="w-full block lg:hidden"
      defaultsrc=""
      height="0"
      imgclasses="w-full h-full overflow-hidden object-contain"
      loading="lazy"
      src="/cms-images/LP-Black-Friday-2024-bg-under-clock-mobile.webp"
      width="0"
    />
  </div>
   
  <div
    class="absolute items-center justify-center transform rotate-45
      top-4 -right-[7.5rem] h-12 w-80 hidden"
  >
    <i
      class="text-4xl text-EXPONDO-white transform -rotate-45"
    />
  </div>
   
  <div
    class="flex w-full lg:w-[300px] bottom-4 left-4 min-h-[188px] lg:min-h-64"
  >
    <div
      class="flex flex-col gap-2 w-full text-EXPONDO-white m-5"
    >
      <productcardbadge-stub
        badgeclass="bg-EXPONDO-white"
        badgetranslationkey=""
        class="hidden"
      />
       
      <div>
        <p
          class="font-bold text-2xl"
        >
          
          lorem ipsum
        
        </p>
         
        <p>
          
          lorem ipsum lorem ipsum
        
        </p>
      </div>
       
      <button-stub
        class="mt-auto"
        size="lg"
        text="lorem button"
        type="secondary"
        url="/search?refinementList%5Bbrand%5D%5B0%5D=Fromm%20%26%20Starck"
      />
    </div>
  </div>
</div>
`;
